<template>
	<v-container class="pt-0">
		<v-row  no-gutters>
			<v-col cols="12" sm="8">
				<v-text-field
					v-model="cupom"
					label="Tem um cupom?"
					prepend-icon="mdi-sale"
					name="cupom"
					required
					ref="cupom"
					@keyup="uppercase"
					@keypress="isValidCoupon = true"
					@keydown="isValidCoupon = true"
          :dark="dark"
          style="text-align:center"
				/>
			</v-col>
			<v-col cols="12" sm="4" class="text-right">
				<v-btn small color="secondary" dark rounded @click.prevent="validateCupom(cupom)" :loading="loading">
					Aplicar
				</v-btn>
			</v-col>
			<v-col cols="12" sm="12" v-if="isValidCoupon === false">
				<div
				class="pt-0 pb-0 caption text-center red--text"
				>
				<!-- Ops! Esse cupom é inválido!. -->
				{{ couponMessage }}
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ['dark'],
  data: () => ({
    cupom: null,
    isValidCoupon: true,
    CupomMessage: null,
	loading: false
  }),
  computed: {
    ...mapState(['lead', 'coupon']),
  },
  methods: {
    ...mapActions(["getCoupons", "getPlan"]),
    uppercase() {
      this.cupom = this.cupom ? this.cupom.toUpperCase() : "";
    },
    lowercase() {
      this.email = this.email ? this.email.toLowerCase() : "";
    },
    validateCupom(cupom) {
      this.loading = true
      if (!cupom || cupom.trim() === "") {
        this.loading = false;
        this.isValidCoupon = true;
        return true;
      }
      return this.getCoupons(cupom)
        .then(async (res) => {
          this.isValidCoupon = res.status;
          this.couponMessage = res.message;
          this.loading = false
          this.lead.coupon = this.coupon
          await this.getPlan(this.lead.coupon.plan_id + this.lead.person_type);
          return res.status;
        })
        .catch((err) => {
          console.error(err);
          this.isValidCoupon = false;
          this.couponMessage = err.message;
          this.loading = false
        //   this.valid = false;
          return false;
        });
    },
  },
};
</script>
<style scoped>
.centered-input input {
  text-align: center
}
</style>