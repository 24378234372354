var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mx-auto mt-2", attrs: { outlined: "", rounded: "" } },
    [
      _c(
        "v-list-item",
        { attrs: { "three-line": "" } },
        [
          _c(
            "v-list-item-content",
            [
              _c("div", { staticClass: "overline mb-4" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.getAddress(_vm.qualification.consumer_unit)
                        ? _vm.getAddress(_vm.qualification.consumer_unit)[0]
                            .address
                        : ""
                    ) +
                    " "
                )
              ]),
              _c("v-list-item-title", { staticClass: "headline mb-1" }, [
                _c("span", { staticClass: "body-1" }, [_vm._v("Imóvel:")]),
                _vm._v(" " + _vm._s(_vm.qualification.consumer_unit) + " ")
              ]),
              _c("p", { staticClass: "secondary--text" }, [
                _vm._v(_vm._s(_vm.disqualification.text))
              ])
            ],
            1
          ),
          _c(
            "v-list-item-avatar",
            { attrs: { tile: "", size: "40" } },
            [
              _c(
                "v-icon",
                { attrs: { "x-large": "", color: "red lighten-1" } },
                [_vm._v("mdi-alert-circle")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }