<template>
    <div>
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-avatar-three-line, image, article"
          v-if="loading"
          width="400"
        ></v-skeleton-loader>
        <v-card 
            class="mx-auto"
            outlined
            rounded
            v-else
        >
            <v-list-item three-line>
                <v-list-item-content>
                    <div class="overline mb-4">
                        {{getAddress(qualification.consumer_unit)?getAddress(qualification.consumer_unit)[0].address:''}}
                    </div>
                    <v-list-item-title class="headline mb-1">
                        {{qualification.consumer_unit}}
                    </v-list-item-title>
                    <v-list-item-subtitle class="font-weight-bold"  v-if="change_bill">Validando dados junto a concessionária...</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="40"
                >
                    <v-progress-circular
                        v-if="qualification.status === 'pending'"
                        indeterminate
                        color="primary"
                    ></v-progress-circular>
                    <!-- <v-icon x-large rigth color="grey lighten-1" @click.prevent="openQualificationDetail(qualification.consumer_unit, qualification.status)" v-if="!qualification.bill_link">mdi-information</v-icon> -->
                    <v-icon x-large rigth color="blue lighten-1" @click.prevent="openQualificationDetail(qualification.consumer_unit, qualification.status)" v-else>mdi-progress-check</v-icon>
                </v-list-item-avatar>
            </v-list-item>

            
            <!-- <div class="pa-4" v-if="change_bill">
                <v-row>
                    <v-col cols="12">
                        <v-file-input
                            accept="image/*,application/pdf"
                            label="Anexar Conta"
                            @change="detectFiles($event)"
                            name="file"
                            v-model="file"
                            :rules="rules"
                            @click:append="clearBill"
                            :append-icon="qualification.bill_link?'mdi-arrow-left-circle':''"
                        ></v-file-input>
                    </v-col>
                </v-row>
                <v-row class="text-center">
                    <v-col cols="6">
                    </v-col>
                    <v-spacer/>
                </v-row>
            </div> -->
            <div v-if="qualification.bill_link" class="pa-4 justify-center align-center">
                <v-row class="pa-2">
                    <v-col cols="11">
                        <v-img v-if="bill_url && getExt(bill_url) === '.pdf'" src="@/assets/pdf.svg" width="200px"/>
                        <v-img v-else :src="bill_url"/>
                    </v-col>
                    <v-col cols="1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon 
                                    @click="editBill" 
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>
                            <span>Alterar Foto</span>
                        </v-tooltip>
                        <!-- <v-icon @click="editBill">mdi-pencil</v-icon> -->
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import { sendBill, getFileNameExt } from '../../services/qualifications'
import { firestorage } from '../../firebase/firestorage'
export default {
    props: ['qualification'],
    computed: {
        ...mapState(['user', 'lead']),
    },
    data: () => ({
      file: null,
      rules: [
        value => !value || value.size < 3000000 || 'Arquivo precisa ser menor que 3 MB!',
      ],
      loading: true,
      change_bill: true,
      bill_url: false,
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }),
    created() {
        if(this.qualification && this.qualification.bill_link) {
            this.loading = true
            let bill = firestorage.ref().child(this.qualification.bill_link)
            return bill.getDownloadURL()
                .then((url) => {
                    this.bill_url = url
                    this.change_bill = false
                    this.loading = false
                })
                .catch((error) => {
                    console.error(error)
                    this.loading = false
                    return null
                })
        }
        else {
            this.loading = false
            return null
        }
    },
    watch: {
        qualification() {
            if(this.qualification && this.qualification.bill_link) {
                this.loading = true
                let bill = firestorage.ref().child(this.qualification.bill_link)
                return bill.getDownloadURL()
                    .then((url) => {
                        this.bill_url = url
                        this.change_bill = false
                        this.loading = false
                    })
                    .catch((error) => {
                        console.error(error)
                        this.loading = false
                        return null
                    })
            }
            else return null
        }
    },
    methods: {
        getAddress(id) {
            if(this.user && this.user.installations_found) {
                return this.user.installations_found.filter(installation => {
                    return installation.installation_number === id
                })
            } else {
                return null
            }
        },
        detectFiles(e) {
            this.loading = true
            if(!this.user || this.user === 'undefined') {
                this.loading = false
                return
            }
            // let fileList = e.target.files || e.dataTransfer.files;
            // let file = fileList[0]
            let file = e
            if(!this.lead) {
                console.error('Ops! Erro ao buscar os dados de cadastro!')
                return
            }
            if(!this.qualification || !this.qualification.id) {
                console.error('Ops! Erro ao pegar o imóvel a qualificar!')
                return
            }
            if(!file) {
                console.error('Ops! Arquivo inválido ou inexistente!')
                return
            }
            sendBill(true, this.qualification.id, file, this.lead)
                .then(() => {
                    this.loading = false
                })
                .catch(err => {
                    console.error(err)
                    this.loading = false
                })

        },
        editBill() {
            this.change_bill = true
        },
        clearBill() {
            this.change_bill = false
        },
        getExt(name) {
            let ext = getFileNameExt(name)
            return ext.substring(0,4)
        }
    }
}
</script>