<template>
    <v-card 
        class="mx-auto mt-2"
        outlined
        rounded
    >
        <v-list-item three-line>
            <v-list-item-content>
                <div class="overline mb-4">
                    {{getAddress(qualification.consumer_unit)?getAddress(qualification.consumer_unit)[0].address:''}}
                </div>
                <v-list-item-title class="headline mb-1">
                    <span class="body-1">Imóvel:</span> {{qualification.consumer_unit}} | {{qualification.consumer_unit_detail?qualification.consumer_unit_detail.percentage:0}}%
                </v-list-item-title>
                <v-list-item-subtitle>Imóvel Qualificado e Aceito</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="40"
            >
                <v-icon x-large color="green lighten-1"  @click.prevent="openQualificationDetail(qualification.consumer_unit, qualification.status)">mdi-check</v-icon>
            </v-list-item-avatar>
        </v-list-item>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
import { eventBus } from '../../main'
export default {
    props: ['qualification'],
    computed: {
        ...mapState(['user'])
    },
    methods: {
        getAddress(id) {
            if(this.user && this.user.installations_found) {
                return this.user.installations_found.filter(installation => {
                    return installation.installation_number === id
                })
            } else {
                return null
            }
        },
        openQualificationDetail(uc, type) {
            this.uc = uc,
            this.qualification_type = type,
            eventBus.$emit('open-dialog_qualification_details')
        }
    }

}
</script>