<template>
    <v-container fluid class="ma-0">
        <v-row wrap>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
            <v-col cols="12" md="6" lg="4" class="pa-0 form">
                <!-- <v-img src="@/assets/usinas/5.jpg" width="100%" height="200px"/> -->
                <steps :step="2" :total="5" :itens="[(lead.installations_found ? 'Selecionar imóveis' : 'Conta de Luz'), 'Formas de Pagamento']"/>
                <v-container class="pa-3" v-if="lead.installations_found">
                    <h3 class="text-center">{{ lead.name }},</h3>
                    <p>Selecione o(s) imóvel(is) que deseja migrar para energia limpa.</p>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="ml-2 mr-2"
                    >
                        <v-row class="ma-2">
                            <v-card
                                color="white"
                                v-if="loading_cpf"
                                flat
                            >
                                <v-card-text>
                                    {{label_loading_cpf}}
                                    <v-progress-linear
                                        indeterminate
                                        color="primary"
                                        class="mb-0"
                                    ></v-progress-linear>
                                </v-card-text>
                            </v-card>
                            <v-card v-if="ucs_cemig && ucs_cemig.length > 0" flat>
                                <v-card-text>
                                    <!-- <p v-if="ucs_cemig && ucs_cemig.length <= 1" class="pr-4 pl-4">Informe o imóvel que você quer contratar.</p> -->
                                    <p v-if="ucs_cemig && ucs_cemig.length > 1" class="pr-4 pl-4">Verificamos que você possui mais de um imóvel cadastrado na Cemig. Selecione todos que você deseja aderir a energia limpa</p>
                                    <v-card v-for="(uc, idx) in ucs_cemig" 
                                        rounded 
                                        v-bind:key="idx" 
                                        class="d-flex justify-center mb-3 pa-3 select_uc" 
                                        color="#efefef"
                                        outlined
                                        @click.prevent="selectUC(uc.installation_number)"
                                    >
                                        <v-row align="center" class="justify-center pl-6 pr-6 pt-3">
                                            <v-col cols="2">
                                                <v-checkbox
                                                    v-model="ucs_chbox"
                                                    :value="uc.installation_number"
                                                    color="blue"
                                                    required
                                                    @click.prevent="selectUC(uc.installation_number)"
                                                ></v-checkbox>
                                            </v-col>
                                            <v-col cols="10">
                                                <h3>{{uc.installation_number}}</h3>
                                                <p>{{uc.address}}</p>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <!-- <v-list
                                        subheader
                                        three-line
                                        flat
                                    >
                                        <v-list-item-group
                                            v-model="settings"
                                            multiple
                                            active-class=""
                                        >
                                            <v-list-item v-for="(uc, idx) in ucs_cemig"  v-bind:key="idx">
                                                <v-container class="row mb-3">
                                                    <v-card outlined elevation="0" class="pa-3 mb-3 row wrap">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                                v-model="ucs_chbox"
                                                                :value="uc.installation_number"
                                                                color="blue"
                                                                required
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title>{{uc.installation_number}}</v-list-item-title>
                                                            <v-list-item-subtitle>{{uc.address}}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-card>
                                                </v-container>
                                            </v-list-item>
                                    </v-list> -->
                                    <!-- <v-alert
                                        color="blue"
                                        outlined
                                        dark
                                        type="info"
                                        dismissible
                                        v-if="ucs_cemig && ucs_cemig.length > 0"
                                    >
                                        <span class="body-2">No momento não atendemos a imóveis cadastrados no programa baixa renda do governo.</span>
                                    </v-alert>
                                    <v-alert
                                        color="blue"
                                        outlined
                                        type="info"
                                        dismissible
                                        v-if="ucs_cemig && ucs_cemig.length > 1"
                                    >
                                        <span class="body-2">Selecione apenas as unidades consumidoras que você é responsável pelo pagamento da conta de luz.</span>
                                    </v-alert> -->
                                    <!-- <v-alert
                                        color="blue"
                                        outlined
                                        type="info"
                                        dismissible
                                        v-if="ucs_cemig && ucs_cemig.length > 1"
                                    >
                                        <span class="body-2">Se você possuir imóveis alugados, indique nossos serviços a seus inquilinos e os ajude a economizar.</span>
                                    </v-alert> -->
                                </v-card-text>
                                <!-- <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" flat @click="dialog_ucs = false">fechar</v-btn>
                                <v-btn color="blue darken-1" @click="ucs_selected()">Selecionar</v-btn>
                                </v-card-actions> -->
                            </v-card>
                        </v-row>
                        <v-row class="xl12 lg12 md12 sm12 xs12 pb-6 ma-0">
                        <!-- <v-btn v-if="ucs_cemig.length > 0" class="btn-assinar" block round color="secondary" :loading="loading_cpf" :disabled="!valid || loading_cpf || ucs_chbox.length === 0"  @click.prevent="validate">Continuar ></v-btn> -->
                            <v-btn
                                v-if="ucs_cemig && ucs_cemig.length > 0"
                                color="secondary"
                                block
                                rounded
                                large
                                :loading="loading_cpf"
                                :disabled="!valid || ucs_chbox.length === 0"  
                                @click="submit"
                                class="text-none"
                                >Continuar
                            </v-btn>
                        </v-row>
                    </v-form>
                </v-container>
                <v-container class="pa-3" v-else>
                    <h3 class="text-center pt-3 px-2">
                        Anexe sua conta de luz para agilizar o processo de qualificação
                    </h3>
                    <v-row class="px-10 mt-10">
                        <v-btn
                            class="mx-0 mt-3 mb-0 py-11 font-weight-light rounded-xl"
                            min-width="100%"
                            color="#E9E5E5"
                            @click="pickIdEnergyBill"
                        >
                            <v-icon
                            v-if="!idEnergyBill[0].name"
                            size="50"
                            color="primary"
                            aria-hidden="true"
                            >
                            mdi-cloud-upload-outline
                            </v-icon>
                            <span class="black--text font-weight-bold" v-else>{{
                            idEnergyBill[0].name
                            }}</span>
                        </v-btn>
                        <input
                            type="file"
                            ref="idEnergyBillInput"
                            style="display: none"
                            @change="pickedEnergyBill($event)"
                            accept="image/*, application/pdf"
                        />
                    </v-row>
                    <h4 v-if="idEnergyBill[0].name" color="black" class="my-5 pt-3 text-center">
                        <a @click="removeEnergyBill" class="black--text"
                            >Remover arquivo
                            <v-icon size="20" color="red" aria-hidden="true">
                            mdi-trash-can-outline
                            </v-icon></a
                        >
                    </h4>
                    <div v-else class="text-container">
                        <p class="mt-4 mb-0 py-6 text-weight-bold text subtitulo">Ou se preferir, faça isso depois através <br/> do nosso canal de atendimento</p>
                    </div>
                    <v-btn
                        color="secondary"
                        block
                        rounded
                        large
                        :loading="loadingBill"
                        @click.prevent="nextStep"
                        class="text-none"
                        >Continuar
                    </v-btn>
                    <h4 class="py-2 text-center" v-if="lead.utility">
                        <a
                            class="text-subtitle-2 black--text"
                            :href="utilityLink"
                            target="_blank"
                            >Obter segunda via da conta de luz da {{ utilityName }}</a
                        >
                    </h4>

                </v-container>
            </v-col>
            <v-col cols="12" xs="12" md="6" lg="4" class="d-none d-md-block bg"/>
        </v-row>
    </v-container>
</template>
<script>
import Steps from '../components/Steps'
import { mapState, mapActions } from 'vuex'
import images from '../utils/images'
import { getQualification } from '../services/cemig'
import { convertRDStation } from '../services/rdstation'
import { firestore } from "../firebase/firestore"
import firebase from 'firebase'

export default {
    components: {
        Steps
    },
    async mounted() {
        if(!this.lead) {
            this.$router.push({path: '/', query: this.$route.query})
            return null
        }


        if(this.lead) {
            if(this.lead.installations_found) {
                this.ucs_cemig = this.lead.installations_found
                this.$gtm.trackEvent({
                    event: 'step3_imovel_encontrado',
                    category: "onboard",
                    action: "pageview",
                    label: "Imovel encontrado",
                    value: this.lead?.average_consumption,
                    noninteraction: false, // Optional
                    step: this.$route.name,
                    person_type: this.lead?.person_type,
                    email: this.lead?.email,
                    phone: this.lead?.phone,
                });
                convertRDStation('step3_imovel_encontrado', this.lead)
                    .catch(err => console.error('Erro ao gravar a conversáo no RD Station. Erro: ', err))
                    .then(() => console.log('Conversão step3_imovel_encontrado gravada com sucesso no RD Station'))
            } else {
                this.$gtm.trackEvent({
                    event: 'step3_imovel_nao_encontrado',
                    category: "onboard",
                    action: "pageview",
                    label: "Imovel nao encontrado",
                    value: this.lead?.average_consumption,
                    noninteraction: false, // Optional
                    step: this.$route.name,
                    person_type: this.lead?.person_type,
                    email: this.lead?.email,
                    phone: this.lead?.phone,
                });
                convertRDStation('step3_imovel_nao_encontrado', this.lead)
                    .catch(err => console.error('Erro ao gravar a conversáo no RD Station. Erro: ', err))
                    .then(() => console.log('Conversão step3_imovel_nao_encontrado gravada com sucesso no RD Station'))
            }
            // else this.$router.push('/metodos-pagamento')
        }

        if(!this.user) {
            await this.getUserInitial(this.lead?.email);
        }

        this.image = images.images[Math.floor((Math.random() * images.images.length))]
    },
    data: () => ({
        ucs_cemig: [],
        settings: null,
        label_loading_cpf: false,
        loading_cpf: false,
        ucs_chbox: [],
        items: [],
        valid: false,
        color: null,
        active: false,
        image: null,
        uc: null,
        ucRules: [
            v => !!v || 'Ops! Por favor, informe a Unidade Consumidora.',
            v => (v && v.length >= 10) || 'Informe a Unidade Consumidora correto.'
        ],
        idEnergyBill: [
            {
                name: null,
                data: null,
            },
        ],
        utilities_links: [
            {
                utility: "cemig",
                name: "CEMIG",
                url: "https://atende.cemig.com.br/Login"
            },
            {
                utility: "neoenergia-pe",
                name: "Neoenergia Pernambuco",
                url: "https://clientescorporativos.neoenergiapernambuco.com.br/_layouts/PCC/login.aspx?ReturnUrl=%2fservicos%2fformularios%2f_layouts%2fAuthenticate.aspx%3fSource%3d%252Fservicos%252Fformularios%252FPaginas%252F2%252Dvia%252Dda%252Dfatura%252Easpx&Source=%2Fservicos%2Fformularios%2FPaginas%2F2%2Dvia%2Dda%2Dfatura%2Easpx"
            },
            {
                utility: "neoenergia-ba",
                name: "Neoenergia Bahia",
                url: "https://servicos.neoenergiacoelba.com.br/area-logada/Paginas/login.aspx?ref=https://servicos.neoenergiacoelba.com.br/servicos-ao-cliente/Pages/2-via-de-conta-coelba.aspx"
            },
            {
                utility: "cpfl-piratininga",
                name: "CPFL Piratininga",
                url: "https://cpflb2cprd.b2clogin.com/cpflb2cprd.onmicrosoft.com/b2c_1a_signup_signin_mfa_front/oauth2/v2.0/authorize?p=B2C_1A_SIGNUP_SIGNIN_MFA_FRONT&client_id=17d5831d-6741-4670-8085-d1d34e37aec1&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fwww.cpfl.com.br%2Fb2c-auth%2Freceive-token&scope=17d5831d-6741-4670-8085-d1d34e37aec1%20offline_access&response_type=code&prompt=login&response_mode=query"
            }
        ],
        loadingBill: false
    }),
    computed: {
        ...mapState(['lead', 'user']),
        utilityLink() {
            const utility = this.utilities_links.find(item => item.utility == this.lead.utility)
            return utility.url
        },
        utilityName() {
            const utility = this.utilities_links.find(item => item.utility == this.lead.utility)
            return utility.name
        },
    },
    methods: {
        ...mapActions(['saveUser', 'saveLead', 'getUserInitial']),
        async submit() {
            let _user = this.lead
            _user.installation_numbers = this.ucs_chbox

            let data = {
                cpf: _user.cpf, 
                origin: "web", 
                consumer_units: _user.installation_numbers,
                customer_id: _user.customer_id
            }

            let _lead = {
                ...this.lead,
                installation_numbers: this.ucs_chbox,
                step: 'UnidadesConsumidoras'
            }
            this.saveLead(_lead)

            this.startQualification(data, _lead)

            await this.saveUser(_user)
                .then(() => {
                    this.$router.push({path: '/metodos-pagamento', query: this.$route.query})
                })
                .catch(err => console.error(err))
            
        },
        async submitUC() {
            let _user = this.lead
            let ucs_chbox = [this.uc]
            _user.installation_numbers = ucs_chbox

            let data = {
                cpf: _user.cpf, 
                origin: "web", 
                consumer_units: [this.uc],
                customer_id: _user.customer_id
            }
            let _lead = {
                ...this.lead,
                installation_numbers: _user.installation_numbers,
                step: 'UnidadesConsumidoras'
            }
            this.saveLead(_lead)

            this.startQualification(data, _lead)

            await this.saveUser(_user)
                .then(() => {
                    this.$router.push({path: '/metodos-pagamento', query: this.$route.query})
                })
                .catch(err => console.error(err))
            
        },
        selectUC(item) {
            let hasItem = this.ucs_chbox.filter((value) => {
                return value === item
            })
            if(hasItem.length === 0) this.ucs_chbox.push(item)
            else {
                this.ucs_chbox = this.ucs_chbox.filter((value) => {
                    return value !== item
                })
            }
        },
        startQualification(data, lead) {
            getQualification(data)
                .then((res) => {
                    //Salva dados no banco qualification_ids (Array de ids)
                    if(res.status === 200) {
                        let _lead = {
                            ...lead,
                            qualification_ids: res.data.ids,
                            step: this.$route.name
                        }
                        this.saveLead(_lead)
                            .catch(err => console.error('Erro ao salvar lead. ', err))
                    } else {
                        console.error(JSON.stringify(res))
                    }
                })
                .catch(err => console.error(err))
        },
        async nextStep () {
            this.loadingBill = true
            let lead = {
                ...this.lead,
                step: 'UnidadesConsumidoras'
            }
            await this.saveLead(lead)

            await this.uploadEnergyBillData()

            this.loadingBill = false
        },
        pickIdEnergyBill () {
            this.$refs.idEnergyBillInput.click()
        },
        async pickedEnergyBill (event) {
            const energyBillFiles = event.target.files
            for (const photo of energyBillFiles) {
                if (photo !== undefined) {
                if (!this.idEnergyBill[0].name) {
                    this.idEnergyBill = []
                }
                this.idEnergyBill.push({
                    name: photo.name,
                    data: photo
                })
                }
            }
        },
        removeEnergyBill () {
            this.idEnergyBill = [
                {
                    name: null,
                    data: null
                }
            ]
        },
        async uploadEnergyBillData () {
            if (this.idEnergyBill && this.lead.id) {
                for (const file of this.idEnergyBill) {
                    let filePath = `users/${this.lead.email}/document_photo/${file.name}`
                    let uploadFile = firebase
                        .storage()
                        .ref()
                        .child(filePath)
                        .put(file.data)

                    uploadFile.on(
                        'state_changed',
                        s => {
                        console.log((s.bytesTransferred / s.totalBytes) * 100 + '%')
                        },
                        error => {
                        console.error(error)
                        throw new Error('Falha ao realizar upload de documentos.')
                        },
                        () => {
                        uploadFile.snapshot.ref.getDownloadURL().then(downloadURL => {
                            firestore
                            .collection('leads')
                            .doc(this.lead.email)
                            .collection('document_photo')
                            .doc(file.name)
                            .set({
                                name: file.name,
                                url: downloadURL,
                                upload_at: new Date(),
                                type: 'energy_bill'
                            })
                        })
                        }
                    )
                }
            } 
            this.$router.push({ path: '/metodos-pagamento', query: this.$route.query })
        }
    }
}
</script>
<style scoped lang="scss">
.text-container {
  display: flex;
  justify-content: center;
}
.select_uc {
  border-radius: 80px !important;
}
.subtitulo {
  position: relative;
  display: inline-block;
  color: #7c3dff;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
}
</style>