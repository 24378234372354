<template>
    <div class="justify-md-center" style="background-color: #FFF">
        <div class="pt-5 pr-10 pl-10 text-center title" style="word-break: break-word;">
            Oie, <strong>{{ lead.name | firstName }}!</strong>
        </div>
        <v-card class="flex-row justify-center mb-6" flat tile>
            <v-card elevation="0"
                :class="(plan && plan.id === item.id) || (!plan && item.recommended) ? 'card_selected pt-1 pb-1 rounded-xl ma-6 justify-center' : 'card pt-1 pb-1 rounded-xl ma-6 justify-center'"
                @click="toggle_plan(item)" :dark="(plan && plan.id === item.id) || (!plan && item.recommended)"
                v-for="item in plans" :key="item.id">
                <v-card-title primary-title class="justify-center">
                    <v-row class="pt-0 mt-0 ma-0 pr-0 pl-0">
                        <v-col cols="12" class="pt-2 pb-2 pr-0 pl-0 mt-0 text-center">
                            <v-row class="pt-0 mt-0 ma-0">
                                <v-col cols="12" class="pt-0 pb-0 mt-0 text-center">
                                    <div class="text-center pt-1 mt-0 pb-0 mb-0"><span class="pa-2 body-3 text-break">Você
                                            <span
                                                style="color:#000; background: #17e380; padding: 5px; border-radius: 4px">vai
                                                economizar</span> mais de uma conta de luz por ano</span></div>
                                    <div class="text-center pt-1 mt-0 pb-0 mb-0 subtitle font-type">ECONOMIA ANUAL</div>
                                    <div class="text-center pt-4 mt-2 pb-0 mb-0">
                                        <span class="economyCurrency pt-0">R$</span>
                                        <span class="economyValueTotal text-center font-weight-bold">{{
                                            calcEconomyY(item.discount * 1)
                                            | formatCurrencyInteger }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                            <p class="caption text-center">
                                <span
                                v-if="
                                    item.id == 'liquidaenergiapj' ||
                                    item.id == 'liquidaenergiapf'
                                "
                                >PROMO</span
                                >
                                ({{ item.discount | formatCurrencyInteger }}% OFF)
                                <span
                                v-if="
                                    item.id == 'liquidaenergiapj' ||
                                    item.id == 'liquidaenergiapf'
                                "
                                >*</span
                                >
                            </p>
                            <p class="body-3 text-left ma-0 pl-14 text-break"><v-icon small
                                    class="pr-2">mdi-account-hard-hat-outline</v-icon>Sem instalações</p>
                            <p class="body-3 text-left ma-0 pl-14 text-break"><v-icon small
                                    class="pr-2">mdi-currency-usd</v-icon>Sem taxa de adesão</p>
                            <p class="body-3 text-left ma-0 pl-14 text-break"><v-icon small
                                    class="pr-2">mdi-file-sign</v-icon>{{ item.contract_time > 0 ? 'Fidelidade de ' +
                                        item.canceled_time : 'Sem fidelidade' }}</p>
                            <p class="body-3 text-left mb-2 pl-14 text-break"><v-icon small
                                    class="pr-2">mdi-auto-fix</v-icon>Sem pegadinhas</p>
                            <v-btn color="#ed0c5e" class="text-none text-break" rounded dark>Checar compatibilidade do meu
                                imóvel</v-btn>
                            <como-funciona :plan="item" />
                            <p
                                v-if="
                                item.id == 'liquidaenergiapj' || item.id == 'liquidaenergiapf'
                                "
                                class="caption desc pb-0 mb-0"
                            >
                                {{ item.percentage_note }}
                            </p>
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-card>
        </v-card>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { eventBus } from "../../main"
import ComoFunciona from './ComoFunciona.vue'
export default {
    props: ["discount"],
    components: {
        ComoFunciona,
    },
    computed: {
        ...mapState(['lead', 'enviroments', 'coupon', 'plan', 'plans', 'utilityCompanies'])
    },
    data: function () {
        return {
            discount_value: this.discount,
            toggle_exclusive: 0,
            economy: null,
            economy_label: 'por ano e',
            benefits: [{
                text: 'Na redução de emissão de <h1>250kg(s)</h1> de co2',
                img: require('@/assets/benefits/co2.jpg'),
                background: 'blue'
            },
            {
                text: 'Isso também equivale ao plantio de <h1>197</h1> arvore(s).',
                img: require('@/assets/benefits/arvores.jpg'),
                background: 'green'
            },
            {
                text: 'E a redução de <h1>28</h1> barril(s) de petróleo.',
                img: require('@/assets/benefits/barris.jpg'),
                background: 'green'
            },
            {
                text: 'E você ainda economiza aproximadamente <h1>R$ 300,00</h1> por ano.',
                img: require('@/assets/benefits/economia.jpg'),
                background: 'green'
            }],
            cupom: null,
            model: null,
        }
    },
    async mounted() {
        if (this.lead) {
            await this.getPlans({person_type: this.lead.person_type, plan_id: null, utility: this.lead.utility})
            if (this.utilityCompanies?.find(item => item.value == this.lead.utility) && this.plans?.length > 0) {
                this.$router.push({path: '/simulador', query: this.$route.query})
            } else {
                this.$router.push({path: '/lista-espera', query: this.$route.query})
            }
            this.economy = (this.lead.average_consumption - 50) * (this.discount_value || 0.15) * 12
            if (this.lead.coupon) {
                this.cupom = this.lead.coupon

            }
        }
    },
    watch: {
        discount(val) {
            this.economy = (this.lead.average_consumption - 50) * (val || 0.15) * 12
        },
        discount_value(val) {
            this.economy = (this.lead.average_consumption - 50) * (val || 0.15) * 12
        },
        lead() {
            this.economy = (this.lead.average_consumption - 50) * (this.discount_value || 0.15) * 12
        },
        coupon(val) {
            this.cupom = val
            if (val && val.percentage_discount) this.discount_value = (val.percentage_discount / 100)
        }
    },
    methods: {
        ...mapActions(["getPlans"]),
        toggle_economy(toggle) {
            if (!toggle) {
                this.economy = (((this.lead ? this.lead.average_consumption : this.economy) * .85) / 12).toFixed(0)
                this.economy_label = 'por mês'
            } else {
                this.economy = ((this.lead ? this.lead.average_consumption : this.economy) * .85).toFixed(0)
                this.economy_label = 'por ano e'
            }
        },
        toggle_plan(plan) {
            this.$store.commit('setPlan', plan)
            eventBus.$emit('startSubscribe')
        },
        showHowWorks() {
            eventBus.$emit('open-dialog_howworks')
            // Grava abertura de página no Amplitude
            // var eventProperties = {
            //     "page": 'howWorks'
            // };
            // this.$amplitude.logEvent('VIEWED', eventProperties);
        },
        delCoupon() {
            this.lead.coupon = null
            this.cupom = null
        },
        calcEconomyM(discount) {
            return (this.lead.average_consumption - 50) * ((discount / 100) || 0.15)
        },
        calcEconomyY(discount) {
            return (this.lead.average_consumption - 50) * ((discount / 100) || 0.15) * 12
        },
        calcPagar(discount) {
            return (this.lead.average_consumption - (this.lead.average_consumption - 50) * ((discount / 100) || 0.15))
        }
    }
}
</script>

<style scoped>
.card {
    color: '#fff';
    background-color: #d5d6ff;
}

.card_selected {
    color: '#fff';
    background-color: #7c3dff;
}

.economyValue {
    font-family: 'Quicksand' !important;
    font-size: 40px;
    font-weight: 900;
    color: #00a4ff;
    text-align: 'center';
    text-decoration: overline;
}

.economyValueNew {
    font-family: 'Quicksand' !important;
    font-size: 40px;
    font-weight: 900 !important;
    color: #4ecc33;
    text-align: 'center';
}

.card_selected .economyValueTotal {
    font-family: 'Quicksand' !important;
    font-size: 80px;
    font-weight: 900 !important;
    color: #d5d6ff;
    text-align: 'center';
    text-decoration: line-through #ed0d5e 3px;
}

.card_selected .subtitle {
    font-family: 'Quicksand' !important;
    font-size: 20px;
    font-weight: 900 !important;
    color: #17e380;
    text-align: 'center';
}

.card .subtitle {
    font-family: 'Quicksand' !important;
    font-size: 20px;
    font-weight: 900 !important;
    color: #1c0b70;
    text-align: 'center';
}

.card .economyValueTotal {
    font-family: 'Quicksand' !important;
    font-size: 80px;
    font-weight: 900 !important;
    color: #7d3dff;
    text-align: 'center';
    text-decoration: line-through #ed0d5e 3px;
}

.economyDecimal {
    font-family: 'Quicksand' !important;
    font-size: 30px;
    font-weight: 600;
    color: #170345;
    vertical-align: 75%;
}

.economyOff {
    font-family: 'Quicksand' !important;
    font-size: 14px;
    font-weight: 600;
    padding-top: 30px;
    margin-left: -10px;
    margin-top: -15px;
    position: absolute;
    transform: rotate(-45deg);
    /* Legacy vendor prefixes that you probably don't need... */
    /* Safari */
    -webkit-transform: rotate(-45deg);

    /* Firefox */
    -moz-transform: rotate(-45deg);

    /* IE */
    -ms-transform: rotate(-45deg);

    /* Opera */
    -o-transform: rotate(-45deg);

    /* Internet Explorer */
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    /* vertical-align:10%; */
}

.card .economyOff {
    color: #7d3dff;
}

.card_selected .economyOff {
    color: #d6d6ff;
}

.economyCurrency {
    font-family: 'Quicksand' !important;
    font-size: 14px;
    font-weight: 600;
    color: #7d3dff;
    vertical-align: 40%;
}

.card_selected .economyCurrency {
    font-family: 'Quicksand' !important;
    font-size: 14px;
    font-weight: 600;
    color: #d6d6ff;
    vertical-align: 40%;
}

.economyCurrency .dark {
    font-family: 'Quicksand' !important;
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    vertical-align: 40%;
}

.economyAte {
    font-family: 'Quicksand' !important;
    font-size: 12px;
    font-weight: 600;
    color: #00a4ff;
    margin-top: -20px;
    position: absolute;
}

.img_topo {
    position: absolute;
    top: -60px;
    left: 32%;
}

.plan_info {
    /* border-radius: 5px; */
    background: #00a4ff;
    /* padding: 8px; */
    word-break: break-word;
}

.my-slider>.v-slide-group__next.theme--light.v-icon {
    color: rgb(234, 10, 10) !important;

}

.desc{
    word-break: break-word;
}
</style>