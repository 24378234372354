var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          }),
          _c(
            "v-col",
            {
              staticClass: "pa-0 form",
              attrs: { cols: "12", md: "6", lg: "4" }
            },
            [
              _c("steps", {
                attrs: {
                  step: 4,
                  total: 5,
                  itens: ["Detalhes da Migração", "Fazer Portabiliade"]
                }
              }),
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    { staticClass: "pa-2 pt-2 pb-0" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-6", attrs: { cols: "12" } },
                        [
                          _c("h2", { staticClass: "text-capitalize" }, [
                            _vm._v(
                              _vm._s(
                                _vm.lead && _vm.lead.name
                                  ? _vm.lead.name
                                  : "Maria da Silva"
                              )
                            )
                          ]),
                          _c("p", { staticClass: "pt-0 pb-0 mb-0" }, [
                            _vm._v(
                              "Cel. " +
                                _vm._s(
                                  _vm.lead && _vm.lead.phone
                                    ? _vm.lead.phone
                                    : "(35) 99999-9999"
                                )
                            )
                          ]),
                          _c("p", { staticClass: "pt-0 mt-0 mb-0" }, [
                            _vm._v(
                              "E-mail: " +
                                _vm._s(
                                  _vm.lead && _vm.lead.email
                                    ? _vm.lead.email
                                    : "maria@teste.com.br"
                                )
                            )
                          ]),
                          _c("p", [
                            _vm._v(
                              "CPF: " +
                                _vm._s(
                                  _vm.lead && _vm.lead.cpf
                                    ? _vm.lead.cpf
                                    : "123.456.789-12"
                                )
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pt-0", attrs: { cols: "12" } },
                        [_c("v-divider")],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { staticClass: "pa-2 pt-0 pb-2" },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("h3", { staticClass: "pb-4" }, [
                            _vm._v(
                              "Confira o(s) imóvel(s) apto(s) a portabilidade de energia limpa agora mesmo!"
                            )
                          ]),
                          _vm.loading_start
                            ? _c(
                                "v-skeleton-loader",
                                _vm._b(
                                  {
                                    attrs: {
                                      type:
                                        "list-item-avatar-three-line, image, article",
                                      width: "400"
                                    }
                                  },
                                  "v-skeleton-loader",
                                  _vm.attrs,
                                  false
                                )
                              )
                            : _vm._e(),
                          _vm._l(_vm.qualifications, function(
                            qualification,
                            idx
                          ) {
                            return _c(
                              "v-row",
                              { key: idx },
                              [
                                qualification.status === "pending"
                                  ? _c("qualifing-card", {
                                      attrs: { qualification: qualification }
                                    })
                                  : _vm._e(),
                                qualification.status === "bill_not_found" ||
                                qualification.status === "error"
                                  ? _c("qualifing-card", {
                                      attrs: { qualification: qualification }
                                    })
                                  : _vm._e(),
                                qualification.status === "disqualified"
                                  ? _c("disqualifield-card", {
                                      attrs: { qualification: qualification }
                                    })
                                  : _vm._e(),
                                qualification.status === "qualified"
                                  ? _c("qualifield-card", {
                                      attrs: { qualification: qualification }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }),
                          _vm.qualifications &&
                          _vm.qualifications.length <= 0 &&
                          _vm.user &&
                          !_vm.user.hasOwnProperty("installations_found")
                            ? _c("v-row", { staticClass: "pa-3" }, [
                                _c("p", [
                                  _vm._v(
                                    "Não conseguimos fazer a validação automática do seu imóvel."
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Mas não tem problema! Continue a sua portabilidade que nosso time fará o processo manualmente."
                                  )
                                ]),
                                _c("p", [
                                  _vm._v(
                                    "Assim que for finalizada você receberá um e-mail de confirmação."
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm.not_qualification === true
                            ? _c("not-qualifing-card", {
                                attrs: { lead: _vm.lead }
                              })
                            : _vm._e()
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      staticClass: "ml-2 mr-2 pt-6",
                      attrs: { "lazy-validation": "" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.submit($event)
                        }
                      },
                      model: {
                        value: _vm.valid,
                        callback: function($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid"
                      }
                    },
                    [
                      _c(
                        "v-row",
                        [
                          _vm.error_message
                            ? _c(
                                "v-col",
                                {
                                  staticClass: "text-center red--text",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c(
                                    "p",
                                    { staticClass: "body-1 text-center" },
                                    [_vm._v(_vm._s(_vm.error_message))]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "text-none",
                                  attrs: {
                                    color: "secondary",
                                    block: "",
                                    rounded: "",
                                    large: "",
                                    loading: _vm.loading,
                                    disabled:
                                      !_vm.valid || _vm.isDisqualification,
                                    type: "submit"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      return _vm.submit($event)
                                    }
                                  }
                                },
                                [_vm._v("Continuar ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("qualification-detail", {
                attrs: { type: _vm.qualification_type, uc: _vm.uc }
              })
            ],
            1
          ),
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }