import { render, staticRenderFns } from "./NotQualifingCard.vue?vue&type=template&id=0c936a7a&"
import script from "./NotQualifingCard.vue?vue&type=script&lang=js&"
export * from "./NotQualifingCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCol,VFileInput,VIcon,VImg,VListItem,VListItemAvatar,VListItemContent,VListItemSubtitle,VRow,VSkeletonLoader,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\gabri\\OneDrive\\Documents\\Juntos Energia\\onboarding\\onboarding\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c936a7a')) {
      api.createRecord('0c936a7a', component.options)
    } else {
      api.reload('0c936a7a', component.options)
    }
    module.hot.accept("./NotQualifingCard.vue?vue&type=template&id=0c936a7a&", function () {
      api.rerender('0c936a7a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Qualification/NotQualifingCard.vue"
export default component.exports