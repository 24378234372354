import { render, staticRenderFns } from "./Cover.vue?vue&type=template&id=39d4758a&scoped=true&"
import script from "./Cover.vue?vue&type=script&lang=js&"
export * from "./Cover.vue?vue&type=script&lang=js&"
import style0 from "./Cover.vue?vue&type=style&index=0&id=39d4758a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39d4758a",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})


/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\gabri\\OneDrive\\Documents\\Juntos Energia\\onboarding\\onboarding\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('39d4758a')) {
      api.createRecord('39d4758a', component.options)
    } else {
      api.reload('39d4758a', component.options)
    }
    module.hot.accept("./Cover.vue?vue&type=template&id=39d4758a&scoped=true&", function () {
      api.rerender('39d4758a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Contract/Cover.vue"
export default component.exports