<template>
    <v-card 
        class="mx-auto mt-2"
        outlined
        rounded
    >
        <v-list-item three-line>
            <v-list-item-content>
                <div class="overline mb-4">
                    {{getAddress(qualification.consumer_unit)?getAddress(qualification.consumer_unit)[0].address:''}}
                </div>
                <v-list-item-title class="headline mb-1">
                    <span class="body-1">Imóvel:</span> {{qualification.consumer_unit}}
                </v-list-item-title>
                <p class="secondary--text">{{disqualification.text}}</p>
            </v-list-item-content>

            <v-list-item-avatar
                tile
                size="40"
            >
                <!-- <v-icon x-large color="red lighten-1" @click.prevent="openQualificationDetail('301444666', 'NOT_QUALIFY')">mdi-alert-circle</v-icon> -->
                <v-icon x-large color="red lighten-1">mdi-alert-circle</v-icon>
            </v-list-item-avatar>
        </v-list-item>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: ['qualification'],
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapState(['user', 'lead']),
        disqualification() {
            if(this.qualification.status === 'disqualified') {
                if(this.qualification.reason_disqualify === 'low_consumption') {
                    return {
                        text: 'Infelizmente não é possível contratar nossos serviços para a média de consumo de R$ ' + (this.qualification.consumer_unit_detail.mediana * this.qualification.consumer_unit_detail.fare_amount).toFixed(2).replace('.', ',')
                    }
                } else if(this.qualification.reason_disqualify === 'not_holder') {
                    return {
                        text: 'Os dados do cadastro não conferem com os dados do imóvel cadastrado na concessionária.'
                    }
                } else if(this.qualification.reason_disqualify === 'low_fare') {
                    return {
                        text: 'Infelizmente não é possível contratar nossos serviços, pois sua tarifa atual já possui beneficios ou é rural'
                    }
                } else if(this.qualification.reason_disqualify === 'low_savings') {
                    return {
                        text: 'Infelizmente não é possível contratar nossos serviços para a média de consumo de R$ ' + (this.qualification.consumer_unit_detail.mediana * this.qualification.consumer_unit_detail.fare_amount).toFixed(2).replace('.', ',')
                    }
                } else if(this.qualification.reason_disqualify === 'debits') {
                    return {
                        text: 'Infelizmente não é possível contratar nossos serviços, pois identificamos restrições junto a concessionária'
                    }
                }  else if (this.qualification.reason_disqualify === "injected_energy") {
                    return {
                        text: "Infelizmente não é possível contratar nossos serviços, pois a unidade consumidora já possui injeção de energia",
                    }
                } else {
                    return {
                        text: 'Infelizmente não é possivel entregar energia limpa para seu imóvel!'
                    }
                }
            } else {
                return {
                        text: 'Infelizmente não é possivel entregar energia limpa para seu imóvel!'
                }
            }
        }
    },
    methods: {
        getAddress(id) {
            if(this.user && this.user.installations_found) {
                return this.user.installations_found.filter(installation => {
                    return installation.installation_number === id
                })
            } else {
                return null
            }
        },
    }
}
</script>