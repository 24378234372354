<template>
  <v-container>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      @submit.prevent="submit"
      class="ml-2 mr-2 pt-6"
    >
      <v-row class="pt-2 pb-0">
        <v-col class="text-center pb-0">
          <p class="text-justify">
            Enquanto conferimos o seu endereço, entenda como funciona nossas
            faturas e selecione a forma de pagamento de sua preferência.
          </p>
          <p class="text-justify">
            Lembrando que a Juntos não cobra por adesão, anuidade e nem
            mensalidade.
          </p>
          <p class="text-h6 mb-0 text-center">Escolha a forma de pagamento:</p>
        </v-col>
      </v-row>
      <v-row class="pt-0 pb-4">
        <v-col cols="4" sm="4" md="4" align="center">
          <v-btn
            small
            rounded
            color="primary"
            dark
            :outlined="payment_type !== 'boleto'"
            @click.prevent="setPaymentType('boleto')"
            block
          >
            <v-icon v-if="payment_type === 'boleto'" color="white" left
              >mdi-check</v-icon
            >
            Boleto
          </v-btn>
        </v-col>
        <v-col cols="4" sm="4" md="4" align="center">
          <v-btn
            small
            rounded
            color="primary"
            dark
            @click.prevent="setPaymentType('credit_card')"
            :outlined="payment_type !== 'credit_card'"
            block
          >
            <v-icon v-if="payment_type === 'credit_card'" color="white" left
              >mdi-check</v-icon
            >
            Cartão</v-btn
          >
        </v-col>
        <v-col cols="4" sm="4" md="4" align="center">
          <v-btn
            small
            rounded
            color="primary"
            dark
            :outlined="payment_type !== 'pix'"
            @click.prevent="setPaymentType('pix')"
            block
          >
            <v-icon v-if="payment_type === 'pix'" color="white" left
              >mdi-check</v-icon
            >
            Pix
          </v-btn>
        </v-col>
      </v-row>

      <v-img
        :src="
          require('@/assets/cards/images/' + currentCardBackground + '.jpeg')
        "
        style="border-radius: 30px"
        v-if="payment_type === 'credit_card'"
      >
        <v-card color="transparent elevation-0" class="white--text pa-5 ma-2">
          <v-row class="pa-2 pt-2 pb-2" justify="space-between">
            <v-col cols="8">
              <v-img
                :src="require('@/assets/cards/images/chip.png')"
                width="48px"
              />
              <!-- <v-img :src="require('@/assets/cards/images' + (card_type) + '.png')" width="48px"/> -->
            </v-col>
            <v-col cols="4" class="pa-2">
              <v-img
                :src="require('@/assets/cards/images/' + card_type + '.png')"
                style="max-height: 70px!important; width: auto!important;"
              />
            </v-col>
            <v-col cols="12" class="">
              <h3>{{ maskCardNumber(cardNumber) || "0000 0000 0000 0000" }}</h3>
            </v-col>
            <v-col cols="9" class="">
              <h4>{{ cardName || "Nikola Tesla" }}</h4>
            </v-col>
            <v-col cols="3">{{ expire || "09/25" }}</v-col>
            <!-- <v-col cols="2">***</v-col> -->
          </v-row>
        </v-card>
      </v-img>
      <v-row v-if="payment_type === 'credit_card'">
        <v-col class="pt-6 pb-0">
          <v-text-field
            v-model="cardName"
            label="Nome como está no cartão"
            required
            :rules="nameRules"
            filled
            rounded
            autofocus
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            type="tel"
            v-model="cardNumber"
            :rules="numberRules"
            label="Numero do Cartão"
            placeholder="####-####-####-####"
            :maxlength="cardNumberMaxLength"
            return-masked-value
            v-mask="cardMask"
            required
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pt-0 pb-0">
          <v-text-field
            v-model="expire"
            :rules="expireRules"
            label="Data de Validade"
            name="expire"
            type="tel"
            placeholder="##/##"
            return-masked-value
            v-mask="'##/##'"
            required
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pt-0 pb-2">
          <v-text-field
            v-model="cvv"
            type="password"
            :rules="cvvRules"
            label="Código de Verificação"
            name="code"
            placeholder="###"
            v-mask="cvvMask"
            required
            filled
            rounded
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <div class="body-2 pa-3 pt-2" v-if="payment_type === 'credit_card'">
        <v-alert type="info" color="primary" dense outlined>
          <p class="font-weight-bold">Você receberá duas contas separadas</p>
          No cartão você pagará pela geração da energia limpa para a Juntos.
          Você continuará pagando a conta da sua concessionária. Mas apenas os
          custos mínimos e/ou excedentes de consumo, encargos e impostos.
        </v-alert>
        <v-alert type="info" color="primary" dense outlined>
          Para segurança, nós iremos realizar uma pré autorização no valor de
          R$1,23 no seu cartão. Isso é apenas para verificação e o valor não
          será cobrado da sua fatura.
        </v-alert>
        <v-alert type="info" color="primary" dense outlined>
          Qualquer cobrança no seu cartão de crédito será realiza somente após a
          efetivação do cadastro e abatimento dos créditos de energia em sua
          conta de luz.
        </v-alert>
      </div>
      <v-card flat v-if="payment_type === 'boleto'">
        <v-card-text v-if="lead.utility === 'cemig'">
          <v-alert type="info" color="primary" dense outlined>
            <p class="font-weight-bold">Pague tudo em um único boleto</p>
            Enviamos mensalmente uma fatura unificada detalhando o consumo da
            energia gerada pelas nossas fazendas e as taxas de transmissão da
            CEMIG e impostos.
          </v-alert>
        </v-card-text>
        <v-card-text v-else>
          <v-alert type="info" color="primary" dense outlined>
            <p class="font-weight-bold">Duas contas separadas</p>
            Você continuará pagando a conta da sua concessionária, mas apenas
            os custos mínimos e/ou excedentes de consumo, encargos e impostos.
            É importante destacar que a cobrança será realizada somente após o
            primeiro ciclo de uso da energia da Juntos.
          </v-alert>
        </v-card-text>
      </v-card>
      <v-card flat v-if="payment_type === 'pix'">
        <v-card-text>
          <v-alert type="info" color="primary" dense outlined>
            <p class="font-weight-bold">Você receberá duas contas separadas</p>
            No PIX você pagará pela geração da energia limpa para a Juntos. Você
            continuará pagando a conta da sua concessionária. Mas apenas os
            custos mínimos e/ou excedentes de consumo, encargos e impostos.
          </v-alert>
        </v-card-text>
      </v-card>
      <div
        class="body-2 red--text font-weight-bold text-center pb-3"
        v-if="error_message"
      >
        {{ error_message }}
      </div>
      <v-btn
        color="secondary"
        class="mr-4 text-none"
        type="submit"
        :disabled="!valid || loading"
        :loading="loading"
        rounded
        block
        large
        v-if="payment_type === 'credit_card'"
      >
        {{ user && user.signedcontract_at ? "Cadastrar Cartão" : "Continuar" }}
      </v-btn>
      <v-btn
        color="secondary"
        class="mr-4 text-none"
        :disabled="!valid || loading"
        :loading="loading"
        rounded
        block
        large
        v-else
        @click="save(true)"
      >
        {{ user && user.signedcontract_at ? "Finalizar" : "Continuar" }}
      </v-btn>
      <!-- <v-btn @click="clear">
        clear
      </v-btn> -->
    </v-form>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { validExpire, getCardType } from "../../utils/creditcard";
import { createCard, cardHash, createCustomer } from "../../services/pagarme";
import { firestore } from "../../firebase/firestore"

export default {
  mounted() {
    if (
      this.user &&
      (!this.user.installation_number ||
        this.user.installation_number.length <= 0)
    ) {
      this.btn_label = "Infomar imóveis";
    }
  },
  data: () => ({
    payment_type: "boleto",
    cardNumberMaxLength: 19,
    cardName: "",
    nameRules: [
      (v) => !!v || "Ops! Por favor, informe o nome que está no cartão.",
      (v) =>
        (v && v.length >= 10) || "Informe o nome conforme cartão de crédito.",
    ],
    cardNumber: "",
    numberRules: [
      (v) => !!v || "Ops! Por favor, informe o número do cartão.",
      (v) => (v && v.length >= 10) || "Informe o número do cartão de crédito.",
      // v => /.+@.+\..+/.test(v) || 'Ops! Esse valide se você digitou o e-mail corretamente.',
    ],
    expire: "",
    expireRules: [
      (v) => !!v || "Por favor, informe a data de validade",
      (v) => (v && v.length > 4) || "Ops. Parece que esse não é um data valida",
      (v) =>
        (v && validExpire(v)) || "Ops. Parece que esse não é um data valida",
    ],
    cvv: null,
    cvvRules: [
      (v) => !!v || "Por favor, informe o código de validade do cartão",
      (v) => (v && v.length >= 3) || "Ops. Parece que esse não é código válido",
    ],
    valid: false,
    btn_label: "Concluir assinatura",
    card_type: "mastercard",
    backgroundImage: [String, Object],
    // currentCardBackground: Math.floor(Math.random()* 25 + 1),
    currentCardBackground: 13,
    amexCardMask: ["#### ###### #####"],
    dinersCardMask: ["#### ###### ####"],
    defaultCardMask: ["#### #### #### ####"],
    cardMask: ["#### #### #### ####"],
    cvvMask: ["###"],
    amexCvvMask: ["####"],
    dinersCvvMask: ["####"],
    loading: false,
    error_message: null,
  }),
  computed: {
    ...mapState(["lead", "user"]),
  },
  watch: {
    cardNumber(val) {
      if (!val || val === "" || val.length < 4) {
        this.card_type = "mastercard";
        return "mastercard";
      }
      let brand = getCardType(val);
      if (brand === "") return "mastercard";
      this.card_type = brand;
      if (brand === "amex") {
        this.cardMask = this.amexCardMask;
        this.cvvMask = this.amexCvvMask;
      }
      if (brand === "diners") {
        this.cardMask = this.dinersCardMask;
        this.cvv = this.dinersCvvMask;
      }
      return brand;
    },
  },
  methods: {
    ...mapActions([
      "saveLead",
      "facebookSignUp",
      "googleSignUp",
      "saveLead",
      "saveUser",
    ]),
    async submit() {
      if (this.$refs.form.validate()) {
        this.error_message = null;

        if (this.valid) {
          this.loading = true;
          await this.createCreditCard();
        }
      }
    },
    async save(pushRoute) {
      if (this.$refs.form.validate()) {
        this.loading = true;

        let unique_billing = false
        if(this?.payment_type == "boleto" && this.lead?.utility == "cemig") {
          unique_billing = true 
        }

        let new_lead = {
          ...this.lead,
          unique_billing,
          payment_type: this.payment_type,
          step: this.$route.name,
        };
        await this.saveLead(new_lead)
          .then(async () => {
            let new_user = {
              ...new_lead,
              unique_billing: unique_billing,
              payment_type: this.payment_type,
              step: this.$route.name,
            };
            await this.saveUser(new_user)
              .then(async (res) => {
                // busca subcollection de documentos no lead
                const documents = await firestore.collection("leads").doc(new_lead.email).collection("document_photo").get();
                if (documents?.size > 0) {
                  let _documents = [];
                  for (const value of documents.docs) {
                    _documents.push(value.data());
                  }
                  for (const value of _documents) {
                    await firestore.collection("users").doc(res?.id).collection("document_photo").add(value)
                  }
                }
                if (pushRoute) {
                  this.$router.push({
                    path: "/validacao",
                    query: this.$route.query,
                  });
                }
                this.loading = false;
              })
              .catch((err) => {
                console.error(err);
              });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
    maskCardNumber(cardNumber) {
      cardNumber = cardNumber.toString();
      let arr = cardNumber.split("");
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== "") {
          arr[index] = "*";
        }
      });
      return arr.join("");
    },
    setPaymentType(type) {
      this.payment_type = type;
    },

    async createCreditCard() {
      await this.save(false)
      let card = {};
      card.card_holder_name = this.cardName;
      card.card_expiration_date = this.expire.replace(/[^\d]/g, "");
      card.card_number = this.cardNumber.replace(/[^\d]/g, "");
      card.card_cvv = this.cvv;

      let buyer_id = this.user.buyer_id;
      let uid = this.user.customer_id;

      // Se não possuir buyer_id, criar buyer_id no pagarme
      if (!buyer_id) {
        const dataPagarme = {
          uid: this.user.customer_id,
          type: "individual",
          country: "br",
          name: this.user.name,
          email: this.user.email,
          phone: this.user.phone || null,
          birthday: this.user.date_of_birth || null,
          gender: this.user.gender || null,
          cpf: this.user.cpf,
        };
        let pagarme_user = await createCustomer(dataPagarme);
        if (pagarme_user.data.extensions) {
          console.error("Erro ao criar usuário no Pagarme");
          this.error_message = "Erro ao cadastrar cartão";
          this.loading = false;
        } else buyer_id = pagarme_user.data.id;
      }

      const hash = await cardHash(card);

      const ccData = {
        card_hash: hash,
        user_id: uid,
        is_default: true,
        is_temp: false,
      };
      try {
        await createCard(ccData)
          .then(async () => {
            await this.$swal({
              title: "Sucesso",
              text: "Seu cartão foi adicionado com sucesso!",
              icon: "success",
              confirmButtonText: "Ok",
            }).then(() => {
              this.$router.push({
                path: "/validacao",
                query: this.$route.query,
              });
            });
          })
          .catch((err) => {
            this.error_message = "Erro ao cadastrar cartão";
            this.loading = false;
            this.$swal({
              title: "Erro",
              text: "Ocorreu um erro ao adicionar seu cartão, por favor tente novamente. Caso o erro persista por favor entre em contato com o suporte.",
              icon: "error",
              confirmButtonText: "Ok",
            });
            throw new Error(err);
          });
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
  },
  filters: {
    paymentTypeDesc: (val) => {
      if (!val) return "";
      if (val === "boleto") return "Boleto";
      else if (val === "pix") return "Pix";
      else return "Cartão de Crédito";
    },
  },
};
</script>
