import { firestore } from "../firebase/firestore"

export const getCoupon = async (coupon) => {
    let today = new Date().toISOString().substr(0,10)
    let _coupon = await firestore.collection('coupons')
        .where('coupon', '==', coupon)
        .where('activated','==', true)
        //.where('expire_at', '>=', today)
        .get()
    if(_coupon && _coupon.size) {
        if(!_coupon.docs[0].data().expire_at || _coupon.docs[0].data().expire_at >= today) return _coupon
        else return null
    }
};

export const updateUsedCoupon = (id) => {
    let couponsRef = firestore.collection('coupons').doc(id)
    return couponsRef
        .get()
        .then(coupon => {
            if(coupon.exists) {
                let used = coupon.data().used
                let data = { used: used + 1 }
                couponsRef.update(data)
            }
        })
        .catch(err => console.error(err))
};
