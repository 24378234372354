var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { wrap: "" } },
        [
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          }),
          _c(
            "v-col",
            {
              staticClass: "pa-0 form",
              attrs: { cols: "12", md: "6", lg: "4" }
            },
            [
              _c("steps", {
                attrs: {
                  step: 2,
                  total: 5,
                  itens: [
                    _vm.lead.installations_found
                      ? "Selecionar imóveis"
                      : "Conta de Luz",
                    "Formas de Pagamento"
                  ]
                }
              }),
              _vm.lead.installations_found
                ? _c(
                    "v-container",
                    { staticClass: "pa-3" },
                    [
                      _c("h3", { staticClass: "text-center" }, [
                        _vm._v(_vm._s(_vm.lead.name) + ",")
                      ]),
                      _c("p", [
                        _vm._v(
                          "Selecione o(s) imóvel(is) que deseja migrar para energia limpa."
                        )
                      ]),
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "ml-2 mr-2",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid"
                          }
                        },
                        [
                          _c(
                            "v-row",
                            { staticClass: "ma-2" },
                            [
                              _vm.loading_cpf
                                ? _c(
                                    "v-card",
                                    { attrs: { color: "white", flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.label_loading_cpf) +
                                              " "
                                          ),
                                          _c("v-progress-linear", {
                                            staticClass: "mb-0",
                                            attrs: {
                                              indeterminate: "",
                                              color: "primary"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.ucs_cemig && _vm.ucs_cemig.length > 0
                                ? _c(
                                    "v-card",
                                    { attrs: { flat: "" } },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _vm.ucs_cemig &&
                                          _vm.ucs_cemig.length > 1
                                            ? _c(
                                                "p",
                                                { staticClass: "pr-4 pl-4" },
                                                [
                                                  _vm._v(
                                                    "Verificamos que você possui mais de um imóvel cadastrado na Cemig. Selecione todos que você deseja aderir a energia limpa"
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(_vm.ucs_cemig, function(
                                            uc,
                                            idx
                                          ) {
                                            return _c(
                                              "v-card",
                                              {
                                                key: idx,
                                                staticClass:
                                                  "d-flex justify-center mb-3 pa-3 select_uc",
                                                attrs: {
                                                  rounded: "",
                                                  color: "#efefef",
                                                  outlined: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.selectUC(
                                                      uc.installation_number
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass:
                                                      "justify-center pl-6 pr-6 pt-3",
                                                    attrs: { align: "center" }
                                                  },
                                                  [
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "2" } },
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            value:
                                                              uc.installation_number,
                                                            color: "blue",
                                                            required: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.selectUC(
                                                                uc.installation_number
                                                              )
                                                            }
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.ucs_chbox,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.ucs_chbox = $$v
                                                            },
                                                            expression:
                                                              "ucs_chbox"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      { attrs: { cols: "10" } },
                                                      [
                                                        _c("h3", [
                                                          _vm._v(
                                                            _vm._s(
                                                              uc.installation_number
                                                            )
                                                          )
                                                        ]),
                                                        _c("p", [
                                                          _vm._v(
                                                            _vm._s(uc.address)
                                                          )
                                                        ])
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass: "xl12 lg12 md12 sm12 xs12 pb-6 ma-0"
                            },
                            [
                              _vm.ucs_cemig && _vm.ucs_cemig.length > 0
                                ? _c(
                                    "v-btn",
                                    {
                                      staticClass: "text-none",
                                      attrs: {
                                        color: "secondary",
                                        block: "",
                                        rounded: "",
                                        large: "",
                                        loading: _vm.loading_cpf,
                                        disabled:
                                          !_vm.valid ||
                                          _vm.ucs_chbox.length === 0
                                      },
                                      on: { click: _vm.submit }
                                    },
                                    [_vm._v("Continuar ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "v-container",
                    { staticClass: "pa-3" },
                    [
                      _c("h3", { staticClass: "text-center pt-3 px-2" }, [
                        _vm._v(
                          " Anexe sua conta de luz para agilizar o processo de qualificação "
                        )
                      ]),
                      _c(
                        "v-row",
                        { staticClass: "px-10 mt-10" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "mx-0 mt-3 mb-0 py-11 font-weight-light rounded-xl",
                              attrs: { "min-width": "100%", color: "#E9E5E5" },
                              on: { click: _vm.pickIdEnergyBill }
                            },
                            [
                              !_vm.idEnergyBill[0].name
                                ? _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        size: "50",
                                        color: "primary",
                                        "aria-hidden": "true"
                                      }
                                    },
                                    [_vm._v(" mdi-cloud-upload-outline ")]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "black--text font-weight-bold"
                                    },
                                    [_vm._v(_vm._s(_vm.idEnergyBill[0].name))]
                                  )
                            ],
                            1
                          ),
                          _c("input", {
                            ref: "idEnergyBillInput",
                            staticStyle: { display: "none" },
                            attrs: {
                              type: "file",
                              accept: "image/*, application/pdf"
                            },
                            on: {
                              change: function($event) {
                                return _vm.pickedEnergyBill($event)
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _vm.idEnergyBill[0].name
                        ? _c(
                            "h4",
                            {
                              staticClass: "my-5 pt-3 text-center",
                              attrs: { color: "black" }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "black--text",
                                  on: { click: _vm.removeEnergyBill }
                                },
                                [
                                  _vm._v("Remover arquivo "),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        size: "20",
                                        color: "red",
                                        "aria-hidden": "true"
                                      }
                                    },
                                    [_vm._v(" mdi-trash-can-outline ")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _c("div", { staticClass: "text-container" }, [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "mt-4 mb-0 py-6 text-weight-bold text subtitulo"
                              },
                              [
                                _vm._v(
                                  "Ou se preferir, faça isso depois através "
                                ),
                                _c("br"),
                                _vm._v(" do nosso canal de atendimento")
                              ]
                            )
                          ]),
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none",
                          attrs: {
                            color: "secondary",
                            block: "",
                            rounded: "",
                            large: "",
                            loading: _vm.loadingBill
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.nextStep($event)
                            }
                          }
                        },
                        [_vm._v("Continuar ")]
                      ),
                      _vm.lead.utility
                        ? _c("h4", { staticClass: "py-2 text-center" }, [
                            _c(
                              "a",
                              {
                                staticClass: "text-subtitle-2 black--text",
                                attrs: {
                                  href: _vm.utilityLink,
                                  target: "_blank"
                                }
                              },
                              [
                                _vm._v(
                                  "Obter segunda via da conta de luz da " +
                                    _vm._s(_vm.utilityName)
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          ),
          _c("v-col", {
            staticClass: "d-none d-md-block bg",
            attrs: { cols: "12", xs: "12", md: "6", lg: "4" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }