var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c(
            "v-skeleton-loader",
            _vm._b(
              {
                attrs: {
                  type: "list-item-avatar-three-line, image, article",
                  width: "400"
                }
              },
              "v-skeleton-loader",
              _vm.attrs,
              false
            )
          )
        : _c(
            "v-card",
            { staticClass: "mx-auto", attrs: { outlined: "", rounded: "" } },
            [
              _c(
                "v-list-item",
                { attrs: { "three-line": "" } },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("div", { staticClass: "overline mb-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.getAddress(_vm.qualification.consumer_unit)
                                ? _vm.getAddress(
                                    _vm.qualification.consumer_unit
                                  )[0].address
                                : ""
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "v-list-item-title",
                        { staticClass: "headline mb-1" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.qualification.consumer_unit) + " "
                          )
                        ]
                      ),
                      _vm.change_bill
                        ? _c(
                            "v-list-item-subtitle",
                            { staticClass: "font-weight-bold" },
                            [
                              _vm._v(
                                "Validando dados junto a concessionária..."
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-avatar",
                    { attrs: { tile: "", size: "40" } },
                    [
                      _vm.qualification.status === "pending"
                        ? _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" }
                          })
                        : _c(
                            "v-icon",
                            {
                              attrs: {
                                "x-large": "",
                                rigth: "",
                                color: "blue lighten-1"
                              },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.openQualificationDetail(
                                    _vm.qualification.consumer_unit,
                                    _vm.qualification.status
                                  )
                                }
                              }
                            },
                            [_vm._v("mdi-progress-check")]
                          )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.qualification.bill_link
                ? _c(
                    "div",
                    { staticClass: "pa-4 justify-center align-center" },
                    [
                      _c(
                        "v-row",
                        { staticClass: "pa-2" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "11" } },
                            [
                              _vm.bill_url &&
                              _vm.getExt(_vm.bill_url) === ".pdf"
                                ? _c("v-img", {
                                    attrs: {
                                      src: require("@/assets/pdf.svg"),
                                      width: "200px"
                                    }
                                  })
                                : _c("v-img", { attrs: { src: _vm.bill_url } })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "1" } },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    on: { click: _vm.editBill }
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [_vm._v(" mdi-pencil ")]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    832262897
                                  )
                                },
                                [_c("span", [_vm._v("Alterar Foto")])]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }